.cardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 40px 24px;
    border-radius: 16px;
    background-color: var(--white);
    max-width: 358px;
    text-align: center;
}

@media (max-width: 1599px) {
    .cardContainer {
        padding: 32px 16px;
    }
}

@media (max-width: 1000px) {
    .cardContainer {
        padding: 86px 24px;
    }
}